<template>
  <div class="warranty contentWrapper">
    <h1>Warranty</h1>
    <div class="content">
      <h2>1 Year Limited Warranty</h2>
      <p>
        NaturalPoint Inc. (“OptiTrack”) warrants to the original consumer or other end user
        (“Customer” or “You”) who purchased directly from NaturalPoint or through an
        Authorized Dealer that the OptiTrack TrackIR™ (excluding software), is free from
        defects in materials and workmanship for a period of one year from the date of
        purchase, with the limitations provided below.
      </p>
      <p>
        Customer’s sole and exclusive remedy under this limited warranty will be, at OptiTrack’s
        determination, repair or replacement of any component part or TrackIR product
        according to the RMA Process described in this warranty.
      </p>
      <p>
        Software is offered “AS IS” without warranty. TO THE EXTENT PERMITTED BY LAW,
        THIS LIMITED WARRANTY IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESS
        OR IMPLIED, INCLUDING ANY IMPLIED WARRANTY OF FITNESS OR SUITABILITY
        FOR A PARTICULAR USE OR PURPOSE, AND OPTITRACK SHALL IN NO EVENT
        BE LIABLE TO PURCHASER FOR INDIRECT OR CONSEQUENTIAL DAMAGES OF
        ANY KIND OR CHARACTER. If the law in your jurisdiction provides otherwise, the
        above limitations and exclusions may not apply to you.
      </p>

      <h3>Warranty Restrictions</h3>
      <p>This warranty shall be voided if:</p>
      <ul>
        <li>the product is not purchased from OptiTrack or an Authorized Dealer;</li>
        <li>the product is modified, tampered with, misused, or subjected to abnormal
working conditions;</li>
        <li>the product is no longer owned by the original consumer or end user;</li>
        <li>the serial number is removed or otherwise illegible; or</li>
        <li>the customer has used the product for any purpose other than a purpose
expressly described in product documentation, or in a manner contrary to
product specifications.</li>
      </ul>

      <p>This warranty does not include product damage, deterioration or malfunction resulting
from:</p>
    <ul>
      <li>accident, abuse, misuse, or neglect;</li>
      <li>exposure to natural elements;</li>
      <li>use of this product in conjunction with accessories, software, other products, or
peripheral equipment; or</li>
      <li>any cause other than a defect in materials or workmanship.</li>
    </ul>
    <p>This warranty also does not cover minor cosmetic damage, including scratches to the
surface of the lens cover.</p>

<h3>RMA Process</h3>
<ol>
  <li><b>If you purchased your TrackIR from an Authorized Dealer:</b> You need to
contact that Authorized Dealer to make a warranty claim. You must make your
warranty request within 1 year of purchase and follow the Authorized Dealer’s
Return Merchandise Authorization (RMA) process. Once you've established a
warranty claim with a valid proof of purchase (such as product registration or a
packing slip / invoice), the Authorized Dealer will repair or replace your TrackIR
at free of charge, at their discretion. Customers are responsible for covering all
shipping costs to send their original TrackIR to the Authorized Dealer for repair or
replacement; Authorized Dealers will cover the return shipping to the customer.</li>
<li><b>If you purchased your TrackIR directly from OptiTrack.com:</b> You need to
submit your warranty request to OptiTrack directly by using the OptiTrack contact
form at <a href='https://optitrack.com/support/#contact-support'>https://optitrack.com/support/#contact-support</a>. You must make your
warranty request within 1 year of purchase. Once you establish a warranty claim
with a valid proof of purchase (such as product registration or packing slip /
invoice), OptiTrack will issue you an RMA number valid for 15 days from the date
of issue. This RMA number, along with the return portion on your original Order
Slip, must be included with all returns. We are unable to accept any product
returns after the expiration date of the RMA. RMAs cannot be extended or re-
issued. Customers are responsible for covering all shipping costs to send their
original TrackIR to OptiTrack for repair or replacement; OptiTrack will cover the
return shipping to the customer.</li>
</ol>

<p>OptiTrack is not responsible for any products mailed to us without valid RMA numbers.</p>
<p>If you have any questions about the warranty process please use our contact for at
<a href='https://optitrack.com/support/#contact-support'>https://optitrack.com/support/#contact-support</a>. Please provide the serial number of
your product. Serial numbers can be located on the TrackIR device, on its cable, or by
checking within the software. Please note that OptiTrack does not offer repair service
for TrackIR non-warranted damage.</p>
    </div>
    <h2>Return Policy</h2>
    <div class="content">
        <p>If you need to return a product to us, please review our Limited Warranty to verify that you are eligible. Once you have verified your eligibility, you must use our <router-link to="/help">contact form</router-link> within 12 months of purchase to request a Return Merchandise Authorization (RMA) number. We will issue you an RMA valid for 15 days from the date of issue. All products returned must be accompanied by a valid RMA number. We are unable to accept any product returns after the expiration date of the RMA. RMAs cannot be extended or re-issued.</p>
        <p>Customers must contact support before any item can be shipped back to NaturalPoint. Support will provide RMA information for items to be returned. Do not send any packages without valid RMA numbers.</p>
        <p>Customers are responsible to cover any and all shipping costs for returning product to NaturalPoint. If you have purchased from NaturalPoint directly, please cut out the return portion on your Order Slip and include it with all returns.</p>
        <p>If you have any questions about the return process please use our <router-link to="/help">contact form</router-link>. Please provide the serial number of your product. Serial numbers can be located on the device, on its cable, or by checking within the software.</p>

    </div>
  </div>
  <!-- END warranty -->
</template>
<script>
export default {
  name: "Warranty"
};
</script>
<style lang="scss" scoped>
.warranty {
  text-align: center;

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }


  .content {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
    padding-bottom: 40px;
    margin-bottom: 80px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }

  a {
    text-decoration: underline;
    color: #ffffff;
  }
}
</style>
